import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import fetch from 'isomorphic-fetch'

export { gql, disableFragmentWarnings } from 'graphql-tag'

export function finalApolloClient(options: Record<string, any>): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache()

  const request = (operation) => {
    operation.setContext({
      headers: options.headers || {}
    })
  }

  const requestLink = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) {
          handle.unsubscribe()
        }
      }
    })
  })

  const client = new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        }
        if (networkError) {
          if (['ServerParseError', 'ServerError'].includes(networkError.name)) {
            const serverError = networkError as any
            if (serverError.statusCode === 502) {
              console.log('[Server error]: 502 Bad Gateway')
            } else {
              console.log(`[Server error]: ${serverError.statusCode}`)
            }
          } else {
            console.log(`[Network error]: ${networkError}`)
          }
        }
      }),
      requestLink,
      new HttpLink({
        uri: options.uri,
        credentials: 'same-origin',
        fetch
      })
    ]),
    cache,
    resolvers: {
      Mutation: {
        updateNetworkStatus: (_, { isConnected }, { cache }) => {
          cache.writeData({ data: { isConnected }})
          return null
        }
      }
    },
  })

  cache.writeData({
    data: {
      isConnected: true
    }
  })

  return client
}
