
import { Vue, Component } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import { getPage } from '~/services/public/Pages'
import { SnowplowService } from '~/services/public/Snowplow'
import { TestLabService } from '~/services/public/TestLab'


@Component({
  async asyncData({ params, error, payload, $config }: Context) {
    if (!params.slug) {
      return {
        page: null,
      }
    }

    if (payload) {
      return { page: payload }
    }

    const uri =
      params.level_1 +
      '/' +
      params.level_2 +
      '/' +
      params.slug +
      $config.appSuffix

    try {
      const response = await getPage($config, uri)

      if (response.loading === false && response.data) {
        if (response.data.contentNode) {
          return {
            page: response.data.contentNode,
          }
        } else {
          throw new Error('Page not found - data error')
        }
      }
    } catch (err) {
      // error({ statusCode: 404, message: 'Page not found' })
      throw new Error('Page not found - response error')
    }
  },
  middleware: ['preview'],
})
export default class SlugLevel3Page extends Vue {
  async mounted() {
    const snowplowService = new SnowplowService()
    const testLabService = new TestLabService(
      this.$axios,
      this.$config,
      snowplowService,
      this.$store
    )
    // adds experiment context to vuex store if page has any experiments.
    // @ts-ignore -- Page is there by default.
    await testLabService.handleExperiments(this.page.blocks)
    // @ts-ignore -- Page is there by default.
    snowplowService.trackViewForPage(this.page)
  }
}
