import { trackPageView, addGlobalContexts, trackStructEvent } from "@snowplow/browser-tracker"
import { CATEGORIES } from "~/constants/category"
import { CATEGORY_SCHEMA, EXPERIMENT_SCHEMA, SCROLL_SCHEMA } from "~/constants/snowplow-schema"

export class SnowplowService {
  trackViewForPage(page: Page) {
    let v4categoryId: number | null = null
    if (page.locationForm) {
      v4categoryId = page.locationForm.categoryId
    }

    let categoryIdent: string = "other"
    if (v4categoryId) {
      const category = CATEGORIES.filter(cat => cat.v4Id === v4categoryId)[0]
      if (category !== null) {
        categoryIdent = category.id
      }
    }
    const context: any = []
    const categoryContext = {
      schema: CATEGORY_SCHEMA,
      data: {
        category: categoryIdent
      }
    }
    const experimentContext = this.getExperimentContextFromCookie()
    context.push(categoryContext)
    if (experimentContext) {
      context.push(experimentContext)
    }

    trackPageView({
      context
    })
  }

  getCookieValue(cookieName) {
    const name = cookieName + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookieArray = decodedCookie.split(";")
    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim()
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return false
  }

  getExperimentContextFromCookie() {
    const cookieValue = this.getCookieValue("x_tl_cx")
    if (cookieValue) {
      const cookieObject = JSON.parse(cookieValue)
      return {
        schema: EXPERIMENT_SCHEMA,
        data: {
          entity: "SNOWPLOW_DOMAIN_USERID",
          entityId: this.getSnowplowDomainIds().domain_userid,
          experiment: cookieObject.name,
          variant: cookieObject.variant
        }
      }
    }
    return false
  }

  getSnowplowDomainIds(domainCookieName?: string): any | false {
    const cookieName = domainCookieName ?? "_sp_"
    const matcher = new RegExp(`${cookieName}id\\.[a-f0-9]+=([^;]+);?`)
    try {
      const match = document.cookie.match(matcher)
      if (match && match[1]) {
        const split = match[1].split(".")
        if (split.length >= 6) {
          return {
            domain_userid: split[0],
            domain_sessionidx: split[2],
            domain_sessionid: split[5]
          }
        }
      }
      return false
    } catch (error) {
      return false
    }
  }

  addExperimentContext(experimentData: any): void {
    const contextEntity = {
      schema: EXPERIMENT_SCHEMA,
      data: experimentData
    }
    addGlobalContexts([contextEntity])
  }

  trackPageScroll(scrollData: any): void {
    const contextData = scrollData
      .filter(section => section.scroll_percentage !== 0)
      .map(section => ({
        schema: SCROLL_SCHEMA,
        data: section
      }))
    trackStructEvent({
      category: "section",
      action: "viewed",
      context: contextData
    })
  }

  trackCategoryCardClick(category: string): void {
    trackStructEvent({
      category: "category_card",
      action: "click",
      property: category
    })
  }

  trackHomePageCtaClick(): void {
    trackStructEvent({
      category: "homepage_cta",
      action: "click",
      property: "homepage_cta_button"
    })
  }

  trackAnyVanTrackId(trackId): void {
    trackStructEvent({
      category: "anyvan_track_id",
      action: "viewed",
      property: trackId
    })
  }

  trackCallUs({ phoneNumber: property, label, extension = "", action }: CallUsPayload) {
    trackStructEvent({
      category: "call_anyvan",
      label,
      action,
      property,
      value: extension ? parseInt(extension) : undefined
    })
  }

  trackButtonClick(text, link) {
    trackStructEvent({
      category: "button",
      action: "click",
      label: link,
      property: text
    })
  }

  trackLinkClick(text, link) {
    trackStructEvent({
      category: "link",
      action: "click",
      label: link,
      property: text
    })
  }

  // ---------------------------- ITEM GENERAL ----------------------------

  trackIOLPDesktopButton(vanCount: number) {
    trackStructEvent({
      category: "iolp_main",
      label: "Search for your items",
      action: "open_dropdown",
      property: "",
      value: vanCount
    })
  }

  trackIOLPDesktopClose(vanCount: number) {
    trackStructEvent({
      category: "iolp_main",
      label: "X icon",
      action: "close_dropdown",
      value: vanCount
    })
  }

  trackIOLPDesktopMenuLeft(vanCount: number) {
    trackStructEvent({
      category: "iolp_menu",
      label: "<",
      action: "slide_left",
      value: vanCount
    })
  }

  trackIOLPDesktopMenuRight(vanCount: number) {
    trackStructEvent({
      category: "iolp_menu",
      label: ">",
      action: "slide_right",
      value: vanCount
    })
  }

  trackIOLPButton(vanCount: number) {
    trackStructEvent({
      category: "iolp_main",
      label: "What are you moving?",
      action: "open_modal",
      property: "",
      value: vanCount
    })
  }

  trackIOLPButtonDisabled(vanCount: number, label) {
    trackStructEvent({
      category: "iolp_main",
      label,
      action: "view_error",
      property: "",
      value: vanCount
    })
  }

  trackCTAButton(modalName, vanCount: number) {
    trackStructEvent({
      category: "iolp_cta",
      label: "Get Instant Prices",
      action: "click",
      property: modalName,
      value: vanCount
    })
  }

  trackCTAButtonDisabled(modalName, vanCount: number, label) {
    trackStructEvent({
      category: "iolp_cta",
      label,
      action: "view_error",
      property: modalName,
      value: vanCount
    })
  }

  trackCTAButtonFailed(modalName, vanCount: number, label) {
    trackStructEvent({
      category: "iolp_cta",
      label,
      action: "failure",
      property: modalName,
      value: vanCount
    })
  }

  trackSummaryButton(modalName, vanCount: number) {
    trackStructEvent({
      category: "iolp_summary",
      label: "^ icon",
      action: "open_modal",
      property: modalName,
      value: vanCount
    })
  }

  trackCategoryButton(categoryName, vanCount: number = 0, action = "open_modal", label = "icon") {
    trackStructEvent({
      category: "iolp_category",
      label,
      action,
      property: categoryName,
      value: vanCount
    })
  }

  trackDoneButton(modalName, vanCount: number = 0, property = "") {
    if (modalName === "iolp_category") {
      trackStructEvent({
        category: "iolp_category",
        label: "Done",
        action: "done_modal",
        value: vanCount,
        property
      })
    } else {
      trackStructEvent({
        category: "iolp_modal_done",
        label: "Done",
        action: "click",
        property: modalName,
        value: vanCount
      })
    }
  }

  trackCloseButton(modalName, vanCount: number = 0, property = "") {
    if (modalName === "iolp_main") {
      trackStructEvent({
        category: "iolp_main",
        label: "X icon",
        action: "close_modal",
        value: vanCount
      })
    } else if (modalName === "iolp_search") {
      trackStructEvent({
        category: "iolp_search",
        label: "X icon",
        action: "close_modal",
        value: vanCount
      })
    } else if (modalName === "iolp_category") {
      trackStructEvent({
        category: "iolp_category",
        label: "X icon",
        action: "close_modal",
        value: vanCount,
        property
      })
    } else if (modalName === "iolp_summary") {
      trackStructEvent({
        category: "iolp_summary",
        label: "X icon",
        action: "close_modal",
        value: vanCount
      })
    } else if (modalName === "iolp_call_us") {
      trackStructEvent({
        category: "iolp_call_us",
        label: "X icon",
        action: "close_modal",
        value: vanCount
      })
    } else {
      trackStructEvent({
        category: "iolp_modal_close",
        label: "X icon",
        action: "click",
        property: modalName,
        value: vanCount
      })
    }
  }

  // ---------------------------- SEARCH ----------------------------

  trackSearchButton(vanCount: number) {
    trackStructEvent({
      category: "iolp_search",
      action: "open_modal",
      value: vanCount
    })
  }

  trackSearchItem(term, resultCount: number) {
    trackStructEvent({
      category: "iolp_search",
      action: "search_item",
      property: term,
      value: resultCount
    })
  }

  trackSearchClear(term) {
    trackStructEvent({
      category: "iolp_search",
      action: "clear_search",
      property: term
    })
  }

  // ---------------------------- QUANTITY ----------------------------

  trackAddSearchItem(itemID, quantity: number, index) {
    trackStructEvent({
      category: "iolp_search",
      label: index + 1,
      action: "add_item",
      property: itemID,
      value: quantity
    })
  }

  trackRemoveSearchItem(itemID, quantity: number, index) {
    trackStructEvent({
      category: "iolp_search",
      label: index + 1,
      action: "remove_item",
      property: itemID,
      value: quantity
    })
  }

  trackAddPopularItem(itemID, quantity: number, index) {
    trackStructEvent({
      category: "iolp_popular",
      label: index + 1,
      action: "add_item",
      property: itemID,
      value: quantity
    })
  }

  trackRemovePopularItem(itemID, quantity: number, index) {
    trackStructEvent({
      category: "iolp_popular",
      label: index + 1,
      action: "remove_item",
      property: itemID,
      value: quantity
    })
  }

  trackAddCategoryItem(itemID, quantity: number, title) {
    trackStructEvent({
      category: "iolp_category",
      label: title,
      action: "add_item",
      property: itemID,
      value: quantity
    })
  }

  trackRemoveCategoryItem(itemID, quantity: number, title) {
    trackStructEvent({
      category: "iolp_category",
      label: title,
      action: "remove_item",
      property: itemID,
      value: quantity
    })
  }

  trackAddSummaryItem(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_summary",
      action: "add_item",
      property: itemID,
      value: quantity
    })
  }

  trackRemoveSummaryItem(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_summary",
      action: "remove_item",
      property: itemID,
      value: quantity
    })
  }

  // ---------------------------- CALL US ----------------------------

  trackCallUsOpen(label, property) {
    trackStructEvent({
      category: "iolp_call_us",
      label,
      action: "open_modal",
      property
    })
  }

  trackCallUsSubmit(property, label, extension) {
    trackStructEvent({
      category: "iolp_call_us",
      label,
      action: "submit",
      property,
      value: extension
    })
  }

  trackCallUsRIQ(label, property) {
    trackStructEvent({
      category: "iolp_call_us",
      label,
      action: "response_iq",
      property
    })
  }

  trackCallUsLiveChat(label, property) {
    trackStructEvent({
      category: "iolp_call_us",
      label,
      action: "live_chat",
      property
    })
  }

  trackCallUsLiveChatClose(label, property) {
    trackStructEvent({
      category: "iolp_call_us",
      label,
      action: "close_live_chat",
      property
    })
  }

  // ---------------------------- ADD / EDIT ----------------------------

  trackCreateItemOpen(itemName, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "open_modal",
      property: itemName
    })
  }

  trackCreateItemConfirm(itemName, quantity: number, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "confirm",
      property: itemName,
      value: quantity
    })
  }

  trackCreateItemSubmit(itemName, quantity: number, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "submit",
      property: itemName,
      value: quantity
    })
  }

  trackCreateItemClose(itemName, quantity: number, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "close_modal",
      property: itemName,
      value: quantity
    })
  }

  trackCreateItemViewWarning(itemName, quantity: number, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "view_warning",
      property: itemName,
      value: quantity
    })
  }

  trackCreateItemViewError(itemName, quantity: number, sourceType) {
    if (!sourceType) sourceType = "other_custom_item"
    trackStructEvent({
      category: "iolp_create_item",
      label: sourceType, // other_custom_item / search_results
      action: "view_error",
      property: itemName,
      value: quantity
    })
  }

  trackEditItemOpen(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "open_modal",
      property: itemID,
      value: quantity
    })
  }

  trackEditItemConfirm(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "confirm",
      property: itemID,
      value: quantity
    })
  }

  trackEditItemSubmit(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "submit",
      property: itemID,
      value: quantity
    })
  }

  trackEditItemClose(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "close_modal",
      property: itemID,
      value: quantity
    })
  }

  trackEditItemViewWarning(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "view_warning",
      property: itemID,
      value: quantity
    })
  }

  trackEditItemViewError(itemID, quantity: number) {
    trackStructEvent({
      category: "iolp_edit_item",
      action: "view_error",
      property: itemID,
      value: quantity
    })
  }
  trackMegaMenuClickEvent(property: string, label: string) {
    trackStructEvent({
      category: "mega-menu",
      action: "click",
      property,
      label
    })
  }
}
