
import { Vue, Component } from 'nuxt-property-decorator'
import { Context } from '@nuxt/types'
import { getPageById } from '~/services/public/Pages'

@Component({
  async asyncData({ query, $config }: Context) {
    try {
      const response = await getPageById($config, query.p)
      if (response.data && response.data.contentNode) {
        return {
          page: response.data.contentNode,
        }
      }
    } catch (err) {
      return {
        page: null,
      }
    }
  },
})
export default class PreviewPage extends Vue {
  page: Record<string, any>

  head() {
    let head = this.page
      ? {
          meta: [
            {
              name: 'robots',
              content:
                this.page!.seo!.metaRobotsNoindex +
                ', ' +
                this.page!.seo!.metaRobotsNofollow,
            },
            {
              name: 'description',
              content: this.page!.seo!.metaDesc,
            },
            {
              property: 'og:type',
              content: this.page!.seo!.opengraphType,
            },
            {
              property: 'og:title',
              content: this.page!.seo!.opengraphTitle,
            },
            {
              property: 'og:description',
              content: this.page!.seo!.opengraphDescription,
            },
          ],
          title: this.page!.title,
          link: [],
        }
      : {}

    if (head.link) {
      if (this.page?.featuredImage?.node) {
        head.link.push({
          // @ts-ignore: in Array
          rel: 'preload',
          // @ts-ignore: in Array
          as: 'image',
          // @ts-ignore: in Array
          href: this.page.featuredImage.node.sourceUrl,
        })
      }

      const canonical = this.page!.seo!.canonical

      if (canonical) {
        head.link.push({
          // @ts-ignore: in Array
          rel: 'canonical',
          // @ts-ignore: in Array
          href: canonical.includes(this.$config.cmsHost)
            ? canonical.replace(this.$config.cmsHost, window.location.origin).replace(/\/$/, "")
            : canonical.replace(/\/$/, "")
        })
      }
    }

    return head
  }
}
