import { render, staticRenderFns } from "./preview.vue?vue&type=template&id=4e4fd946&lang=pug"
import script from "./preview.vue?vue&type=script&lang=ts"
export * from "./preview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageLayout: require('/app/components/generic/PageLayout.vue').default})
