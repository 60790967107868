import { Vue, Component } from "nuxt-property-decorator"

@Component
export default class ImageUrlS3 extends Vue {
  replaceUrlS3(url: string) {
    if (!url) {
      return ""
    }

    if (
      this.$config.graphqlEndpoint.includes("cms.anyvan.local") ||
      this.$config.graphqlEndpoint.includes("dev-cms.anyvan.com")
    ) {
      return url
    }

    if (url.includes("/app/uploads")) {
      // WordPress media library images that have been auto-uploaded to S3
      return url.replace(
        new RegExp(this.$config.cmsHost + "/app/uploads", "g"),
        (this.$config.frontendHost || this.$config.v4Host) + "/content-uploads"
      )
    }

    if (url.includes("/app/themes/anyvan")) {
      // WordPress theme images
      return url.replace(
        new RegExp(this.$config.cmsHost + "/app/themes/anyvan/assets/img", "g"),
        "/content-static/wp-theme"
      )
    }

    return url
  }
}
